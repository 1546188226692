import { Button, Col, Input, Row, Space, Typography, Form } from "antd";
import { HomeOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
import emailjs from "@emailjs/browser";

import Head from "../../../components/sectionHead";

const { Text, Title } = Typography;
const { TextArea } = Input;

const ContactUs = () => {
  const [form] = Form.useForm();

  const validatePhone = (_rule: any, value: any) => {
    const regExp = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;

    if (!value) {
      return Promise.reject(`please enter your Phone number!`);
    } else if (!regExp.test(value)) {
      return Promise.reject(`please enter a valid number`);
    } else {
      return Promise.resolve();
    }
  };

  function onFinish(values: any) {
    emailjs
      .sendForm(
        "service_l82pyum",
        "template_us5cewg",
        "#miform",
        "user_YM5RXaCp7C9RE9C5Dki87"
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    form.resetFields();
  }

  return (
    <div id="contact">
      <Head title={"Contact Us"} />
      <Row>
        <Col span={10} className="contact-us-info">
          <Space direction="vertical">
            <div>
              <PhoneOutlined className="contact-us-icon" />
              <Text className="contact-details">+91-9845711600</Text>
            </div>
            <div>
              <MailOutlined className="contact-us-icon" />
              <Text className="contact-details conatct-email">
                contactus@samparkbindhu.in
              </Text>
            </div>
            <div>
              <HomeOutlined className="contact-us-icon" />
              <address className="contact-details contact-address">
                SamparkBindhu Solutions Private Limited C/o 91080 Workspace, 1st
                floor, Navarathna Garden, Doddakallasandra, Bengaluru, Karnataka
                - 560062
              </address>
            </div>
            <div className="mapouter">
              <div className="gmap_canvas">
                <iframe
                  className="gmap_iframe"
                  width="100%"
                  frameBorder="0"
                  scrolling="no"
                  src="https://maps.google.com/maps?width=387&amp;height=179&amp;hl=en&amp;q=617, samparkbindhu pvt.ltd bangalore&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                ></iframe>
              </div>
            </div>
          </Space>
        </Col>
        <Col span={2}></Col>
        <Col span={10} className="write-feedback">
          <div className="feedback-form">
            <Title className="write-feedback-title" level={4}>
              We would like to hear from you
            </Title>
            <Form
              labelCol={{ span: 24 }}
              form={form}
              id="miform"
              onFinish={onFinish}
            >
              <Form.Item
                name="fullName"
                label={<Text>Full Name</Text>}
                rules={[
                  {
                    required: true,
                    message: "please enter a valid name",
                  },
                  {
                    min: 3,
                  },
                ]}
              >
                <Input name="fullName" placeholder="Enter your Full Name" />
              </Form.Item>
              <Form.Item
                name="email"
                label={<Text>Email</Text>}
                rules={[{ required: true }, { type: "email" }]}
              >
                <Input name="email" placeholder="Enter your email" />
              </Form.Item>
              <Form.Item
                name="phone"
                label={<Text>Phone</Text>}
                rules={[
                  {
                    required: true,
                    validator: (_rule, value) => validatePhone(_rule, value),
                  },
                  { min: 10 },
                ]}
              >
                <Input name="phone" placeholder="Enter phone number" />
              </Form.Item>
              <Form.Item name="message" label={<Text>Message</Text>}>
                <TextArea name="message" />
              </Form.Item>
              <Form.Item>
                <Button block htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ContactUs;
