import React from "react";
import { Card, Collapse, Image, Typography } from "antd";

import { updateInfo } from "./updateInfo";

import Head from "../../../components/sectionHead";

const { Panel } = Collapse;
const { Text, Title } = Typography;

export default function updates() {
  return (
    <div className="contact-wrapper container-fluid" id="updates">
      <Head title={"Updates"} />
      <div className="update-wrapper">
        {updateInfo.map((update: any) => {
          return (
            <React.Fragment>
              <Collapse accordion defaultActiveKey={[updateInfo.length]}>
                <Panel
                showArrow={false}
                  header={
                    <React.Fragment>
                      <div style={{textAlign:'left'}}>
                      <strong>{update?.title}</strong>&nbsp;&nbsp;
                      </div>
                    </React.Fragment>
                  }
                  key={update?.key}
                >
                  <React.Fragment>
                    <div className="cardWrapper">
                      <Card className="update-card" bordered={false}>
                        {update.content &&
                          update.content.map((content: any) => {
                            return (
                              <React.Fragment>
                                {content.id % 2 === 0 ? (
                                  <div className="cardContent">
                                    <Title className="innertitle" key={content.id}>
                                      {content.title}
                                    </Title>
                                    <div className='cardDescription' key={content.id}>
                                      <Text className='innerText'>
                                        {content?.description}
                                      </Text>
                                      <Image
                                        src={content?.img}
                                        preview={false}
                                        className="cardImage"
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="cardContent">
                                    <Title className="innertitle" key={content.id}>
                                      {content.title}
                                    </Title>
                                    <div className='cardDescription' key={content.id}>
                                      <Image
                                        src={content?.img}
                                        preview={false}
                                        className="cardImage"
                                      />
                                      <Text className='innerText'>
                                        {content?.description}
                                      </Text>
                                    </div>
                                  </div>
                                )}
                              </React.Fragment>
                            );
                          })}
                      </Card>
                    </div>
                  </React.Fragment>
                </Panel>
              </Collapse>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};
