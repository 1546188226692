import { Typography, Image } from "antd";
import Head from "../../../components/sectionHead";
import image1 from "../../../assets/images/beta.png";
import { articleInfo } from "./articleInfo";

const { Text, Title } = Typography;

const Articles = () => {
  return (
    <div id="articles">
      <Head title={"Articles"} />
      <div className="articleContainer">
        {articleInfo.map((article) => {
          return (
            <div key={article.key} className="articleBorder">
              <Title className ='articleTitle'>{article.title}</Title>
              {article.key % 2 === 0 ? (
                <div className="articleContent">
                  <p className="articleDescription">
                    {article.description}
                    <span>
                      <a href={article.href} target="_blank">
                        &nbsp;&nbsp; please click here to read more
                      </a>
                    </span>
                  </p>
                  <Image className="articleImage" src={article.img}></Image>
                </div>
              ) : (
                <div className="articleContent">
                  <Image className="articleImage" src={article.img}></Image>
                  <p className="articleDescription">
                    {article.description}
                    <span>
                      <a href={article.href} target="_blank">
                        &nbsp;&nbsp; please click here to read more
                      </a>
                    </span>
                  </p>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Articles;
