import { Image, Typography } from 'antd';

interface propType {
    imgSrc: string,
    title: string,
    text: string
};

export default function WhoElement(props: propType) {
    return (
        <div className='who-element'>
            <Image src={props.imgSrc} alt={props.title} />
            <Typography.Title level={3}>{props.title}</Typography.Title>
            <Typography.Paragraph>{props.text}</Typography.Paragraph>
        </div>
    );
};
