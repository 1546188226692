import React from "react";
import { ArrowDownOutlined } from "@ant-design/icons";
import { Typography, Image, Alert } from "antd";

const { Text } = Typography;

const Banner = () => {
  return (
    <div className="banner" id="banner">
      <Alert
        banner
        className="alertUpdate"
        icon={<></>}
        message={
          <React.Fragment>
            <a style={{color:'black'}} href="#updates">
              <div className="wrapper">
                <ul className="dynamic-txts">
                  <li>
                    🎉Vikasbandhu Beta is available!
                  </li>
                  <li>
                    Patents filed🎉
                  </li>
                </ul>
              </div>
            </a>
          </React.Fragment>
        }
      ></Alert>
      <Image
        className="bannerImage"
        src="./samparkbindu 3.png"
        alt=""
        preview={false}
      />
      <Typography.Link href="#whoWeAre" className="scroll-title">
        <Typography.Title level={2} className="scroll-title">
          SCROLL <br />
          <ArrowDownOutlined />
        </Typography.Title>
      </Typography.Link>
    </div>
  );
};

export default Banner;
