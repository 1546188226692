import { Typography } from 'antd';

import './customStyles.scss';

export default function Head(props: { title: string }) {
    return (
        <div className='category-title'>
            <Typography.Title className='head-title' level={2} >
                <span className='head-title-span'>{props.title}</span>
            </Typography.Title>
        </div>
    );
};
