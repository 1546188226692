import { useState } from 'react';
import { Dropdown, Menu } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

import './customStyles.scss';

const MobileDrawer = () => {
    const [visible, setVisible] = useState(false);

    const menu = (
        <Menu
            className='dropdown-menu'
            onClick={() => setVisible(true)}
            theme='dark'
        >
            <Menu.Item key={1}>
                <a href='#whoWeAre'>Who We Are</a>
            </Menu.Item>
            <Menu.Item key={2}>
                <a href='#whatweoffer'>What We Offer</a>
            </Menu.Item>
            <Menu.Item key={3}>
                <a href='#team'>Mgmt. Team</a>
            </Menu.Item>
            <Menu.Item key={4}>
                <a href='#contact'>Contact Us</a>
            </Menu.Item>
            <Menu.Item key={5}>
                <a href='https://vikasbandhu.in/terms&conditions'>Terms</a>
            </Menu.Item>
            {/* <Menu.Item key={6}>
                <a>Tech Corner</a>
            </Menu.Item> */}
            <Menu.Item key={7}>
                <a href='#updates'>Updates</a>
            </Menu.Item>
            <Menu.Item key={8}>
                <a href='#articles'>Articles</a>
            </Menu.Item>
        </Menu>
    );

    return (
        <div className='mobile-visible'>
            <Dropdown
                className='mobile-dropdown'
                trigger={['click']}
                overlay={menu}
                onVisibleChange={() => setVisible(!visible)}
                visible={visible}
            >
                <MenuOutlined style={{ fontSize: '1.5rem' }} />
            </Dropdown>
        </div>
    );
};

export default MobileDrawer;
