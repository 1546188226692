import { Typography, Image } from 'antd'

const { Title, Text } = Typography

interface param {
    text: string;
    name: string;
    designation: string;
    imgUrl: string;
};

export default function TeamElement(props: param) {
    return (
        <div className='team-element'>
            <div className='image-name'>
                <Image className='team-image' src={props.imgUrl} alt='' preview={false} />
                <Title level={5} className='team-title'>{props.name}</Title>
                <Text className='designation'>{props.designation}</Text>
            </div>
            <div className='text'>{props.text}</div>
        </div>
    );
};
