export const articleInfo = [
  {
    key: 1,
    title: `Artificial Intelligence–An Introduction`,
    description: `Inception of Artificial Intelligence (AI) was in early twentieth
        century. The advancements in the last decade has surged beyond
        expectations and taken the world by storm, and its adoption is
        across virtually all`,
    img: require("../../../assets/images/artificalIntel.jpeg"),
    href:`https://medium.com/@gsm1973/artificial-intelligence-an-introduction-d05eb05548b7`
  },
  {
    key: 2,
    title: `Quantum Computing — Using Spin Resonance via Electrical fields`,
    description: `Engineers claim to have solved a theoretical 
        puzzle posed approximately sixty years back. The thought was 
        to find means to manipulate an`,
    img: require("../../../assets/images/quantum.jpeg"),
    href:`https://medium.com/@gsm1973/quantum-computing-using-spin-resonance-via-electrical-fields-593d1fc8f139`
  },
  {
    key: 3,
    title: `Technology Disruptions — Intro to Quantum Computing`,
    description: `The scale of disruptions in Electronics and Computer Science seen in the last few decades has been phenomenally disruptive. 
        The invention of transistors and integrated circuits (ICs) in the 
        early 1950s triggered the`,
    href: `https://medium.com/@gsm1973/technology-disruptions-intro-of-quantum-computing-addbe5d953b6`,
    img:require("../../../assets/images/technologyDis.jpg"),
  }
];
