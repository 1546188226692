import React from "react";

const link = (
  <div>
    We recently acheived our Beta availability of VikasBandhu Application . It
    is available as an Android App,
    <span>
      <a
        href="https://play.google.com/store/apps/details?id=com.vikasBandhu"
        target="_blank"
      >
        click here
      </a>
    </span>{" "}
    and a Browser application
    <span>
      <a href="https://vikasbandhu.in/" target="_blank">
        click here
      </a>
    </span>
    The Field trials and sample trading are underway to help us get a better
    understanding of the market Our Soft Launch is expected in Jan'23.
  </div>
);

// document.body.innerHTML = link;
// document.body.innerHTML = link1;

export const updateInfo = [
  {
    title: "Innovation",
    key: 5,
    date: "10th to 16th Jan 2022",
    content: [
      {
        title: "Patents Filed",
        id: 1,
        description: `Innovation is a key driver for 
                 technology disruption. SamparkBindhu is investing 
                efforts to drive innovation and protect them as well. 
                We are glad to have filed our first patent on 2nd September 2022!`,
        img: require("../../../assets/images/patentsFiled.png"),
      },
    ],
  },
  {
    title: "Industry Collaboration",
    key: 4,
    date: "5th January 2022",
    content: [
      {
        id: 1,
        title: "Techbharat 2022",
        description: `We were selected to showcase our solution
            'VikasBandhu' in the first Pre-Event “Food and
            Agri Tech exhibition” in Belagavi, Karnataka by
            Techbharat. The forum is using this opportunity to show case a vast
            array of exciting start-ups showcasing innovation and enable
            networking in the Agri tech space This is a three-fold event which
            continues in Bangalore in March and culminates in Mysore in May’21`,
        img: require("../../../assets/images/techExibit.jpg"),
      },
      {
        id: 2,
        title: "Startup India Innovation Week",
        description: `We are selected to showcase our solution 'VikasBandhu'
            in a week-long Innovation week virtual exhibition
            covering 600+ shortlisted Pan India startups in various
            domains. This is a Startup India initiative promoted by
            the Department of Promotion of Industry and Industrial
            Trade celebrating “Azadi ke 75 Years Mahotsav”`,
        img: require("../../../assets/images/E_exhibit.png"),
      },
    ],
  },
  {
    title: "Acamedia Connect",
    key: 3,
    date: "27th - 28th December 2021",
    content: [
      {
        id: 1,
        title: "Memorandum of Understanding",
        description: `The industry and academia collaboration is
            paramount and the need of the hour. We are glad to
            have signed a MOU with Sri Vidyanikethan
            Engineering College (SVEC) in the areas of the AI, Voice recognition,
            Big Data and Cloud first apps. This collaboration will be a win-win. The
            faculty and its students get the required exposure to the latest industry
            trends to apply their domain knowledge to solve cutting-edge real-life
            problems in AgriTech. SamparkBindhu will also attain critical mass
            needed to accelerate and fast pace its technology driven product lines`,
        img: require("../../../assets/images/mou.jpg"),
      },
      {
        id: 2,
        title: "Internship",
        description: `We have four college interns working with us from Sri Krishna Institute of Technology ,
         Bengaluru and Jyothy Institute of Technology , Bengaluru .
          We are trying to  enable/establish  Centre of excellences in the areas of Cloud Development and Automation .`,
        img: require("../../../assets/images/jothiLogo.png"),
      },
    ],
  },
  {
    title: "Milestone",
    key: 2,
    date: "27th December 2021",
    content: [
      {
        id: 1,
        title: "Beta Release",
        description: link,
        img: require("../../../assets/images/beta.png"),
      },
    ],
  },
  {
    title: "General",
    key: 1,
    date: "21st December 2021",
    content: [
      {
        id: 1,
        title: "Funding",
        description: `Received funding through Startup India Seed fund via Jyothi institute of technology bengaluru`,
        img: require("../../../assets/images/funding.png"),
      },
      {
        id: 2,
        description: `Selected for Pre Incubation Grant with UAS -Dharwad via krishik`,
        img: require("../../../assets/images/krishik.png"),
      },
    ],
  },
];
