import { Typography, Button } from 'antd';
import { FileTextOutlined } from '@ant-design/icons'

export default function TechCorner() {
    return (
        <div className='techcorner' style={{ marginTop: '100px' }}>
            <FileTextOutlined style={{ fontSize: 200, marginBottom: 40 }} />
            <Typography.Title level={3}>Articles by G Sadananda Murthy</Typography.Title>
            <Button type='link' href='https://medium.com/@gsm1973'>
                View
            </Button>
        </div>
    );
};
